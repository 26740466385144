{
  "name": "tty.wtf",
  "version": "0.25.41",
  "description": "Type To Yourself ",
  "private": true,
  "main": "index.js",
  "scripts": {
    "start": "node build --serve",
    "build": "node build",
    "start-old": "esbuild src/index.js --target=es6 --bundle --sourcemap --outfile=./index.js --loader:.js=jsx --format=iife --external:fs --external:path --external:child_process --external:ws --watch --serve=8460 --servedir=. --serve-fallback=index.html",
    "build-old": "esbuild src/index.js --target=es6 --bundle --sourcemap --outfile=./index.js --loader:.js=jsx --format=iife --external:fs --external:path --external:child_process --external:ws",
    "test": "echo \"Error: no test specified\" && exit 1"
  },
  "keywords": [
    "unicode",
    "editor",
    "format",
    "javascript"
  ],
  "author": "Oleg Mihailik",
  "license": "MIT",
  "dependencies": {
    "@milkdown/kit": "^7.5.5",
    "@milkdown/plugin-math": "^7.5.0",
    "@milkdown/theme-nord": "^7.5.0",
    "esbuild": "^0.24.2",
    "highlight.js": "^11.10.0"
  },
  "devDependencies": {
    "@types/node": "^22.5.1",
    "@wasmer/sdk": "^0.9.0",
    "ag-grid-community": "^33.0.4",
    "alasql": "^4.5.2",
    "csv-parse": "^5.6.0",
    "echarts": "^5.5.1",
    "rehype-format": "^5.0.1",
    "rehype-stringify": "^10.0.1",
    "remark": "^15.0.1",
    "remark-gfm": "^4.0.0",
    "remark-rehype": "^11.1.1",
    "ts-jsonp": "^5.7.2",
    "typescript": "^5.7.2"
  }
}
